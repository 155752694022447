import React, { useState, useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import CountdownTime from "../CountdownTime/CountdownTime";
import {
    GAME_STATUS,
    SOCKET_ACTIONS,
    SOCKET_RECEIVE_EVENTS,
} from "../../helpers/constant";
import useSocketEvents from "../../hooks/useSocketEvents";
import { getLabel } from "../../helpers/Global";

export default function HomeCountdown({
    gameStatus,
    css,
    listenerEvent = SOCKET_RECEIVE_EVENTS.WAITING_COUNTDOWN,
    uiLabels,
}) {
    const [isActive, setIsActive] = useState(false);

    const [hour, setHour] = useState("00");
    const [minute, setMinute] = useState("00");
    const [second, setSecond] = useState("00");
    const [sound, setSound] = useState(new Audio());

    //if gamestatus changes to COUNTDOWN then disable timer
    useEffect(() => {
        setIsActive(false);
        setHour("00");
        setMinute("00");
        setSecond("00");
    }, []);

    useEffect(() => {
        sound.loop = true;

        return () => {
            sound.pause();
        };
    }, []);

    // receive in-game // question timer
    const handleHomeCountdown = useCallback(
        (time) => {
            const [hour, minute, second] = time.split(":");
            if (hour == "00" && minute == "00" && second == "00") {
                setIsActive(false);
                setHour("00");
                setMinute("00");
                setSecond("00");

                if (
                    gameStatus == GAME_STATUS.COUNTDOWN &&
                    listenerEvent == SOCKET_RECEIVE_EVENTS.START_COUNTDOWN
                ) {
                    sound.pause();
                }

                return;
            }

            setIsActive(true);
            setHour(hour);
            setMinute(minute);
            setSecond(second);

            if (
                gameStatus == GAME_STATUS.COUNTDOWN &&
                hour == "00" &&
                minute == "00" &&
                second == "10" &&
                listenerEvent == SOCKET_RECEIVE_EVENTS.START_COUNTDOWN
            ) {
                sound.src = `${process.env.REACT_APP_SOCKET_URL}/sounds/mix-tick-tock.wav`;
                sound.play();
                return;
            }

            if (!gameStatus) {
                sound.pause();
            }
        },
        [listenerEvent, sound, gameStatus]
    );

    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        listenerEvent,
        null,
        handleHomeCountdown
    );

    return (
        <>
            <Box
                display="flex"
                justifyContent={
                    !css
                        ? {
                              xs: "center",
                              md: "center",
                          }
                        : css.justifyContent
                }
                sx={{
                    width: "100%",
                    marginTop: 1,
                }}
            >
                <CountdownTime
                    number={hour}
                    type={getLabel(uiLabels, "labelHours", "Hours")}
                    isActive={isActive}
                />
                <CountdownTime
                    number={minute}
                    type={getLabel(uiLabels, "labelMinutes", "Minutes")}
                    isActive={isActive}
                    mx={2}
                />
                <CountdownTime
                    number={second}
                    type={getLabel(uiLabels, "labelSeconds", "Seconds")}
                    isActive={isActive}
                />
            </Box>
        </>
    );
}
