import { useEffect } from "react";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { addSocket } from "../reducers/SocketReducer";
import { addAlert, removeAlert } from "../reducers/AlertReducer";
import { Language } from "@mui/icons-material";
import { getGuestData } from "../helpers/Global";

let counter = 0;

const useSocket = (token, language, uiLabels, roomId = "") => {
    const dispatch = useDispatch();
    const socketFromStore = useSelector(({ socket }) => socket.socket);

    useEffect(() => {
        if (
            token !== null &&
            counter === 0 &&
            socketFromStore === null &&
            language &&
            uiLabels
        ) {
            let public_trivia_player = getGuestData();

            const data = {
                token: token,
                language: language,
                uiLabels: uiLabels.join(","),
                roomId: roomId,
                public_trivia_player: public_trivia_player
                    ? JSON.stringify(public_trivia_player)
                    : null,
            };

            const socket = io(process.env.REACT_APP_SOCKET_URL, {
                query: data,
            });

            counter++;
            //client side
            socket.on("connect", () => {});

            socket.on("disconnect", (reason) => {
                dispatch(
                    addAlert({
                        title: "DISCONNECTED",
                        message:
                            "You’ve been disconnected. Don’t worry, you can still rejoin the game without losing your points! Just refresh the page.",
                        buttons: [
                            {
                                text: "Reconnect",
                                callback: () => {
                                    //update parent url as well with roomId
                                    let message = { action: "refreshPage" };
                                    //method to send message to parent outside iframe
                                    window.top.postMessage(message, "*");
                                    dispatch(removeAlert());
                                },
                            },
                        ],
                    })
                );
            });

            dispatch(addSocket(socket, true));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, Language, uiLabels]);
};

export default useSocket;
