import React, { useRef, useEffect, useState } from "react";
import { CssBaseline, Box, Container, Grid, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import PlayersEndScreen from "../../components/PlayersEndScreen/PlayersEndScreen";
import { useSelector } from "react-redux";
import EndScreenButtons from "../../components/EndScreenButtons/EndScreenButtons";
import EndScreenModal from "../../components/EndScreenModal/EndScreenModal";
import Confetti from "react-confetti";
import { SOCKET_SEND_EVENTS } from "../../helpers/constant";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import { useNavigate } from "react-router-dom";
import SnackBar from "../../helpers/SnackBar";
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import {
    getLabel,
    isPrizeWinner,
    toggleMojoPoints,
    updateMojoPoints,
} from "../../helpers/Global";
import Prize from "../../components/Prize/Prize";
import KeepPlaying from "../../components/KeepPlaying/KeepPlaying";
import ActiveGames from "../../components/ActiveGames/ActiveGames";
import ButtonDeleteGame from "../../components/ButtonDeleteGame/ButtonDeleteGame";
import CashBattleInfo from "../../components/CashBattleInfo/CashBattleInfo";

const EndScreen = ({ uiLabels, socketFromStore }) => {
    const endScreenContainer = useRef();
    const theme = useTheme();
    const [endScreenHeight, setEndScreenHeight] = useState(0);
    const navigate = useNavigate();

    const token = useSelector(({ token }) => token.token);

    const message = useSelector(({ message }) => message.message);
    const openSnackbar = useSelector(({ message }) => message.openSnackbar);

    const title = useSelector(({ settings }) => settings.currentTitle);
    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    const requiredPoints = useSelector(({ game }) => game.requiredPoints);

    const multiplayerCategory = useSelector(
        ({ game }) => game.multiplayerCategory
    );

    const me = useSelector(({ player }) => {
        return player;
    });

    const [winner, setWinner] = useState(null);
    const [player, setPlayer] = useState(null);

    const error = useSelector(({ message }) => message.error);

    const [openModal, setOpenModal] = useState(false);

    const [showWinnerMessage, setShowWinnerMessage] = useState(false);

    useEffect(() => {
        toggleMojoPoints(true);
    }, []);

    useEffect(() => {
        setEndScreenHeight(endScreenContainer.current.clientHeight);
    }, [endScreenHeight]);

    useEffect(() => {
        if (!players) {
            return;
        }

        setWinner(players.find((player) => player.stand === 1));
        setPlayer(players.find((player) => player.id === me.playerId));
    }, [players]);

    const handleMessage = (message) => {
        switch (message.data.action) {
            case "redeemed":
                if (player && player.prizeAmount && player.prizeAmount > 0) {
                    //open modal to redeem prize
                    setOpenModal(true);
                } else {
                    socketFromStore.emit(SOCKET_SEND_EVENTS.REDEEM_PRIZE);
                    navigate("/gameover");
                }
                break;

            case "showMessage":
                if (player && player.prizeAmount && player.prizeAmount > 0) {
                    setShowWinnerMessage(true);
                }

                break;

            default:
                break;
        }
    };

    useEffect(() => {
        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [player]);

    function handleModalClose() {
        setOpenModal(false);
    }

    const handleRedeemPrize = () => {
        if (player && player.prizeAmount && player.prizeAmount > 0) {
            //open modal to redeem prize
            setOpenModal(true);
        } else {
            socketFromStore.emit(SOCKET_SEND_EVENTS.REDEEM_PRIZE);
            navigate("/gameover");
        }
    };

    return error === "" ? (
        <>
            <CssBaseline />
            <Container
                maxWidth="lg"
                sx={{
                    position: "relative",
                    padding: "0",
                    height: "auto",
                }}
                ref={endScreenContainer}
            >
                {player && player.isLeader === true ? (
                    <Confetti
                        width={window.innerWidth - 16}
                        height={endScreenHeight}
                    />
                ) : (
                    ""
                )}
                <Grid
                    container
                    sx={{
                        ml: 0,
                        pt: { xs: "0", md: 4 },
                        px: { xs: 0, md: "10%" },
                    }}
                    spacing={0}
                >
                    <Grid item xs={12}>
                        <Prize
                            requiredPoints={requiredPoints}
                            me={player}
                            winner={winner}
                            theme={theme}
                            uiLabels={uiLabels}
                            handleRedeemPrize={handleRedeemPrize}
                        ></Prize>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            display={"flex"}
                            justifyContent={"center"}
                            sx={{ my: 3 }}
                        >
                            <CashBattleInfo uiLabels={uiLabels} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 0, px: { xs: 2, md: 0 } }}>
                        <PlayersEndScreen
                            showLeaderboardTitle={true}
                            uiLabels={uiLabels}
                        />
                        {/* <EndScreenButtons uiLabels={uiLabels} /> */}
                    </Grid>

                    <Grid item xs={12} lg={12}>
                        <Box sx={{ mt: 5, px: { xs: 2, md: 0 } }}>
                            <ActiveGames
                                uiLabels={uiLabels}
                                header={getLabel(
                                    uiLabels,
                                    "labelKeepPlayingShort",
                                    `Keep Playing`
                                )}
                                games={multiplayerCategory}
                                theme={theme}
                            />
                        </Box>
                    </Grid>
                </Grid>
                {message !== "" && (
                    <SnackBar open={openSnackbar} message={message} />
                )}

                <EndScreenModal
                    openModal={openModal}
                    handleModalClose={handleModalClose}
                    uiLabels={uiLabels}
                />
                <ButtonDeleteGame uiLabels={uiLabels} />
                <AlertDialog />
            </Container>
        </>
    ) : (
        <ErrorPage />
    );
};

export default EndScreen;
