const initialState = {
    players: [],
    challengedPlayers: [],
};

const OnlinePlayersReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ONLINE_PLAYERS":
            return Object.assign({}, state, {
                players: action.data,
            });

        case "ADD_ONLINE_PLAYER":
            //only append if player is not on the list
            if (
                [...state.players].find(
                    (player) => player.playerId == action.data.playerId
                )
            )
                return state;

            return Object.assign({}, state, {
                players: [...state.players, action.data],
            });

        case "REMOVE_ONLINE_PLAYER":
            return Object.assign({}, state, {
                players: [...state.players].filter((player) => {
                    return player.playerId != action.data;
                }),
            });

        case "CHALLENGE_PLAYER":
            //only append if player is not on the list
            if (
                [...state.challengedPlayers].find(
                    (playerId) => playerId == action.data
                )
            ) {
                console.log("PLAYER ALREADY CHALLENGED!!!!");
                return state;
            }

            return Object.assign({}, state, {
                challengedPlayers: [...state.challengedPlayers, action.data],
            });

        default:
            return state;
    }
};

//HANDLE
export const setOnlinePlayers = (players) => {
    return {
        type: "ONLINE_PLAYERS",
        data: players,
    };
};

export const addOnlinePlayer = (player) => {
    return {
        type: "ADD_ONLINE_PLAYER",
        data: player,
    };
};

export const removeOnlinePlayer = (playerId) => {
    return {
        type: "REMOVE_ONLINE_PLAYER",
        data: playerId,
    };
};

export const challengePlayer = (playerId) => {
    return {
        type: "CHALLENGE_PLAYER",
        data: playerId,
    };
};

export default OnlinePlayersReducer;
