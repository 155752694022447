import {
    Avatar,
    Box,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { getLabel } from "../../helpers/Global";
import { SOCKET_SEND_EVENTS } from "../../helpers/constant";

import css from "./OnlinePlayers.module.css";

export default function OnlinePlayers({ me, uiLabels, socketFromStore }) {
    const onlinePlayers = useSelector(
        ({ onlinePlayers }) => onlinePlayers.players
    );

    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    const handleChallenge = (player) => {
        socketFromStore.emit(SOCKET_SEND_EVENTS.CHALLENGE, player.playerId);
    };

    return (
        <>
            <Box>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: { xs: "16px", md: "16px" },
                        fontWeight: 500,
                        width: "100%",
                        textAlign: {
                            xs: "center",
                            md: "center",
                        },
                    }}
                >
                    {`${getLabel(
                        uiLabels,
                        "labelOnlinePlayers",
                        "Challenge online players to start a game!"
                    )}`}
                </Typography>
                <Box display={"flex"} justifyContent={"center"}>
                    <List
                        className={css.onlinePlayersContainer}
                        sx={{
                            width: "100%",
                            maxWidth: 300,
                            px: 1,
                            maxHeight: { xs: "200px" },
                            overflowY: "auto",
                        }}
                    >
                        {onlinePlayers.map((player, index) => {
                            if (player.playerId === me.playerId) {
                                return true;
                            }

                            if (
                                players.find((p) => {
                                    return p.id === player.playerId;
                                })
                            ) {
                                return true;
                            }

                            return (
                                <ListItem
                                    key={index}
                                    secondaryAction={
                                        <Button
                                            variant="text"
                                            sx={{
                                                fontSize: "0.8rem",
                                                "&:disabled": {
                                                    color: "gray",
                                                },
                                            }}
                                            onClick={() => {
                                                handleChallenge(player);
                                            }}
                                            disabled={
                                                player.challenged ? true : false
                                            }
                                        >
                                            {player.challenged
                                                ? "CHALLENGED"
                                                : "CHALLENGE"}
                                        </Button>
                                    }
                                    sx={{ p: "4px", margin: "0px !important;" }}
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            alt={`${player.playerAvatar}`}
                                            src={`${player.playerAvatar}`}
                                        />
                                    </ListItemAvatar>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontSize: {
                                                xs: "1rem",
                                                md: "0.8rem",
                                            },
                                        }}
                                    >
                                        {player.playerName}
                                    </Typography>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </Box>
        </>
    );
}
