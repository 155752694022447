/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, CssBaseline, Container, Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Players from "../../components/Players/Players";
import styles from "./Play.module.css";
import { useTheme } from "@mui/material/styles";

import useMediaQuery from "@mui/material/useMediaQuery";
import {
    SOCKET_SEND_EVENTS,
    SOCKET_RECEIVE_EVENTS,
    SOCKET_ACTIONS,
    GAME_STATUS,
    TRUE_FALSE,
} from "../../helpers/constant";

import {
    findPlayer,
    putSelfToStart,
    toggleMojoPoints,
} from "../../helpers/Global";

import {
    updateQuestion,
    updateMaxSeconds,
} from "../../reducers/QuestionsReducer";
import {
    playerReset,
    populatePlayers,
    playerAnswered,
    updateQuestionIndex,
} from "../../reducers/GameReducer";
import { updateCorrectAnswer } from "../../reducers/AnswersReducer";
import useSocketEvents from "../../hooks/useSocketEvents";
import SnackBar from "../../helpers/SnackBar";
import MultipleAnswers from "../../components/MultipleAnswers/MultipleAnswers";

import ErrorPage from "../../components/ErrorPage/ErrorPage";

import circleBG from "./bg_trivia_livestream_circle.png";
import squareBG from "./bg_trivia_livestream_box.png";

import questionMark from "./bg_trivia_livestream_questionmark.png";
import exclamationPoint from "./bg_trivia_livestream_exclamation.png";

import AlertDialog from "../../components/AlertDialog/AlertDialog";
import AlertCookie from "../../components/AlertCookie/AlertCookie";
import RoundBreak from "../../components/RoundBreak/RoundBreak";
import InputAnswer from "../../components/InputAnswer/InputAnswer";
import Question from "../../components/Question/Question";
import PowerUps from "../../components/PowerUps/PowerUps";
import { updateUsedPowerUps } from "../../reducers/PlayerReducer";
import ActiveGames from "../../components/ActiveGames/ActiveGames";

const Play = ({ uiLabels, socketFromStore }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const matches = useMediaQuery("(min-width:400px)");
    const playerContainer = useRef();

    const isDesktop =
        useMediaQuery(theme.breakpoints.up("md")) == true ? true : false;

    const answerContainerRef = useRef();
    const powerUpsContainerRef = useRef();
    const answersContainerRef = useRef();

    const openSnackbar = useSelector(({ message }) => message.openSnackbar);
    const message = useSelector(({ message }) => message.message);

    const me = useSelector(({ player }) => player);

    const [questionStart, setQuestionStart] = useState(false);

    const roomId = useSelector(({ game }) => game.roomId);
    const gameStatus = useSelector(({ game }) => game.gameStatus);
    const questions = useSelector(({ game }) => game.questions);
    const round = useSelector(({ game }) => game.round);
    const questionIndex = useSelector(({ game }) => game.questionIndex);
    const questionObj = useSelector(({ question }) => question);

    const multiplayerCategory = useSelector(
        ({ game }) => game.multiplayerCategory
    );

    const [questionsCount, setQuestionCount] = useState(
        questions[round] ? questions[round].length : 0
    );

    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    const powerUps = useSelector(({ game }) => game.powerUps);

    const questionGrid = useRef();

    const correctAnswer = useSelector(({ answers }) => answers.correctAnswer);
    const [yourAnswer, setYourAnswser] = useState(null);

    const [selectedAns, setSelectedAns] = useState(null);
    const [clicked, setClicked] = useState(false);
    const [hintInactive, setHintInactive] = useState(false);

    const [gameHeight, setGameHeight] = useState("auto");
    const [questionHeight, setQuestionHeight] = useState("auto");
    const [thumbnailHeight, setThumbnailHeight] = useState("auto");

    const error = useSelector(({ message }) => message.error);

    let sound = new Audio();
    let [playSound, setPlaySound] = useState(false);

    const [desktopImageWidth, setDesktopImageWidth] =
        useState("700px !important");
    const [desktopQuestionFontSize, setDesktopQuestionFontSize] =
        useState("1.7rem");
    const [desktopQuestionCount, setDesktopQuestionCount] = useState("0.9rem");

    //this is the answer that will be hidden once player uses show hint
    const [hideAnswer, setHideAnswer] = useState([]);
    const [swapInactive, setSwapInactive] = useState(true);

    useEffect(() => {
        toggleMojoPoints(true);
    }, []);

    useEffect(() => {
        const buttonSize = 45 * 5;
        const timerSizer = 0;
        const offset = 50; //isDesktop ? 15 : 0;
        const newheight =
            gameHeight -
            buttonSize -
            timerSizer -
            (playerContainer && playerContainer.current
                ? playerContainer.current.offsetHeight
                : 0) -
            (powerUpsContainerRef && powerUpsContainerRef.current
                ? powerUpsContainerRef.current.offsetHeight
                : 0) -
            offset;

        setThumbnailHeight(`${newheight}px`);
    }, [isDesktop, gameHeight, powerUpsContainerRef, answersContainerRef]);

    // handle answer options clicked event
    const handleAnswerClick = useCallback(
        (answer) => {
            if (me.spectator) return;
            setYourAnswser(answer);
            socketFromStore.emit(SOCKET_SEND_EVENTS.ANSWER_QUESTION, {
                roomId: roomId,
                playerId: me.playerId,
                answer: answer,
            });
        },
        [me]
    );

    const handlePlayerAnswered = useCallback(
        (playerId, answer) => {
            dispatch(playerAnswered(playerId));

            if (playerId == me.playerId) {
                // also store the answer clicked
                setSelectedAns(answer);
                setClicked(true);
            }
        },
        [dispatch, me]
    );

    //add PLAYER_ANSWERED event,
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYER_ANSWERED,
        null,
        handlePlayerAnswered
    );

    // receive showNextQuestion event
    const handleQuestionStart = useCallback(
        ({ questionIndex, questionTime, speech }) => {
            setYourAnswser(null);

            setHideAnswer([]);

            setQuestionStart(true);

            dispatch(playerReset(["didAnswer", "isCorrect"]));
            setClicked(false);
            setHintInactive(false);

            dispatch(updateCorrectAnswer(undefined));
            dispatch(updateMaxSeconds(questionTime));

            dispatch(updateQuestionIndex(questionIndex));

            if (!questions[round] || !questions[round][questionIndex]) {
                return;
            }

            const question = questions[round][questionIndex];
            dispatch(
                updateQuestion(
                    question.id,
                    question.question,
                    question.videoTitle,
                    question.type,
                    question.answers,
                    question.thumbnail
                )
            );

            if (playSound && sound && speech !== undefined && speech !== null) {
                sound.volume = 0.7;
                sound.pause();
                sound.src = speech;
                setTimeout(() => {
                    sound.play();
                }, 500);
            }

            // method to send message to parent outside iframe
            let message = { action: "refreshAds" };
            window.parent.postMessage(message, "*");
        },
        [dispatch, playSound, questions, round]
    );

    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.QUESTION_STARTS,
        null,
        handleQuestionStart
    );

    // event when question ends
    const handleQuestionEnds = useCallback(
        (data) => {
            const pastPlayers = {};

            players.forEach((player) => {
                pastPlayers[player.id] = player;
            });

            data.playersStats.forEach((player) => {
                if (!pastPlayers[player.id]) return;

                //check if there are changes in bonus and show it
                const pastPlayerBonus = pastPlayers[player.id];
                player.showRibbon = pastPlayerBonus.showRibbon ? true : false;

                //if either does not exist, then skip check
                if (
                    !player.hasOwnProperty("bonusPoints") ||
                    !pastPlayerBonus.hasOwnProperty("bonusPoints") ||
                    !player.hasOwnProperty("answerSpree") ||
                    !pastPlayerBonus.hasOwnProperty("answerSpree")
                ) {
                    return;
                }

                if (
                    player.bonusPoints.length >
                        pastPlayerBonus.bonusPoints.length &&
                    player.answerSpree > pastPlayerBonus.answerSpree
                ) {
                    player.showRibbon = true;
                }

                //if player skips or made a wrong answer, hide ribbon
                if (player.answerSpree <= pastPlayerBonus.answerSpree) {
                    player.showRibbon = false;
                }
            });

            setHintInactive(true);
            setSwapInactive(true);
            setQuestionStart(false);
            dispatch(updateCorrectAnswer(data.correctAnswer));

            dispatch(
                updateUsedPowerUps(findPlayer(data.playersStats, me.playerId))
            );
            dispatch(
                populatePlayers(putSelfToStart(data.playersStats, me.playerId))
            );
        },
        [dispatch, players]
    );

    // when round ends
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.QUESTION_ENDS,
        null,
        handleQuestionEnds
    );

    const handleShowHint = useCallback(
        (answer) => {
            if (!questionObj) {
                return;
            }
            setHintInactive(true);
            const answers = [...questionObj.answers];
            const hide = [];
            const max = answers.length / 2;
            while (hide.length < max) {
                const index = Math.floor(Math.random() * 4);

                if (answers[index].id == answer) {
                    continue;
                }

                if (hide.includes(answers[index].id.toString())) {
                    continue;
                }

                hide.push(answers[index].id.toString());
            }

            setHideAnswer(hide);
        },
        [dispatch, questionObj, hintInactive]
    );

    //add SHOW_HINT event,
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.SHOW_HINT,
        null,
        handleShowHint
    );

    const handleSwapPoints = useCallback(
        (active) => {
            setSwapInactive(!active);
        },
        [dispatch]
    );

    //add SHOW_HINT event,
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.POWERUP_SWAP,
        null,
        handleSwapPoints
    );

    const handlePlaySound = () => {
        setPlaySound((prev) => {
            return !prev;
        });
    };

    const handleMessage = (event) => {
        const payload = event.data;

        if (payload.action) {
            switch (payload.action) {
                //if host, once a roomId is created from reactApp update watchmojo url
                case "gameHeight":
                    setGameHeight(payload.height);
                    const newHeight =
                        payload.height -
                        (playerContainer && playerContainer.current
                            ? playerContainer.current.offsetHeight
                            : 0);
                    setQuestionHeight("auto");
                    break;
            }
        }
    };

    const handleWindowResize = () => {
        let message = { action: "gameHeight" };
        //method to send message to parent outside iframe
        window.top.postMessage(message, "*");
    };

    useEffect(() => {
        setQuestionCount(questions[round] ? questions[round].length : 0);
    }, [round, questions]);

    useEffect(() => {
        handleWindowResize();
        window.addEventListener("resize", handleWindowResize);

        window.addEventListener("message", handleMessage);

        if (!questions[round] || !questions[round][questionIndex]) {
            return () => {
                window.removeEventListener("message", handleMessage);
                window.removeEventListener("resize", handleWindowResize);
            };
        }

        const question = questions[round][questionIndex];
        dispatch(
            updateQuestion(
                question.id,
                question.question,
                question.videoTitle,
                question.type,
                question.answers,
                question.thumbnail
            )
        );

        return () => {
            window.removeEventListener("message", handleMessage);
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return (
        questionObj !== undefined &&
        (error === "" ? (
            <>
                <CssBaseline />
                <Container
                    sx={{
                        position: "relative",
                        padding: "0px !important",
                        width: "100% !important",
                        height: { xs: "100%", md: "100%" },
                        maxWidth: "100% !important",
                        // backgroundColor: round == 1 ? "#040720" : "transparent",
                    }}
                >
                    {gameStatus === GAME_STATUS.QUESTIONS && (
                        <Box display={"flex"} justifyContent={"center"}>
                            <Box
                                sx={{
                                    display:
                                        gameStatus == GAME_STATUS.QUESTIONS &&
                                        questionObj.id
                                            ? "block"
                                            : "none",
                                    width: {
                                        xs: "100% !important",
                                        md: desktopImageWidth,
                                    },
                                }}
                            >
                                {/* bg images */}
                                <img
                                    src={circleBG}
                                    className={`${styles.bgIconsDefault} ${styles.circleBG}`}
                                    alt="bg1"
                                />
                                <img
                                    src={questionMark}
                                    className={`${styles.bgIconsDefault} ${styles.questionBG}`}
                                    alt="bg1"
                                />

                                <img
                                    src={squareBG}
                                    className={`${styles.bgIconsDefault} ${styles.squareBG}`}
                                    alt="bg1"
                                />

                                <img
                                    src={exclamationPoint}
                                    className={`${styles.exclamationBG}`}
                                    alt="bg1"
                                />
                                {/* bg images */}
                                <Box
                                    container
                                    sx={{
                                        ml: 0,
                                        height: "auto",
                                    }}
                                    spacing={{ xs: "0" }}
                                >
                                    {me &&
                                        me.playerId &&
                                        !me.hasConsent &&
                                        !isNaN(me.playerId) && (
                                            <AlertCookie uiLabels={uiLabels} />
                                        )}

                                    <Box item xs={12} ref={playerContainer}>
                                        <Players
                                            players={players}
                                            questionStart={questionStart}
                                        />
                                    </Box>

                                    <Box
                                        item
                                        xs={12}
                                        ref={questionGrid}
                                        sx={{
                                            position: "relative",
                                            height: "auto",
                                            maxHeight: { sm: `40rem` },
                                            minHeight: { xs: `30rem` },
                                            width: "100%",
                                            position: "relative",
                                        }}
                                    >
                                        <Box>
                                            <Grid
                                                container
                                                spacing={0}
                                                sx={{
                                                    width: "100%",
                                                    position: "relative",
                                                }}
                                            >
                                                <Grid item xs={12}>
                                                    <Question
                                                        theme={theme}
                                                        questionStart={
                                                            questionStart
                                                        }
                                                        questionObj={
                                                            questionObj
                                                        }
                                                        questionIndex={
                                                            questionIndex
                                                        }
                                                        questionsCount={
                                                            questionsCount
                                                        }
                                                        desktopQuestionCount={
                                                            desktopQuestionCount
                                                        }
                                                        desktopQuestionFontSize={
                                                            desktopQuestionFontSize
                                                        }
                                                        thumbnailHeight={
                                                            thumbnailHeight
                                                        }
                                                        socketFromStore={
                                                            socketFromStore
                                                        }
                                                        playSound={playSound}
                                                        handlePlaySound={
                                                            handlePlaySound
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    ref={powerUpsContainerRef}
                                                    item
                                                    xs={12}
                                                    sx={{ mb: 1 }}
                                                >
                                                    <PowerUps
                                                        hintInactive={
                                                            hintInactive
                                                        }
                                                        powerUps={powerUps}
                                                        questionStart={
                                                            questionStart
                                                        }
                                                        clicked={clicked}
                                                        spectator={me.spectator}
                                                        points={me.points}
                                                        usedPowerUps={
                                                            me.usedPowerUps
                                                        }
                                                        swapInactive={
                                                            swapInactive
                                                        }
                                                        theme={theme}
                                                        uiLabels={uiLabels}
                                                    />
                                                </Grid>
                                            </Grid>

                                            {me.spectator && (
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        paddingBottom: 1,
                                                        fontSize: {
                                                            xs: "16px",
                                                            md: "16px",
                                                        },
                                                        fontWeight: 900,
                                                        width: "100%",
                                                        textAlign: "center",
                                                        textTransform:
                                                            "uppercase",
                                                        // textShadow:
                                                        //     "1px 1px rgba(0, 179, 226, 1), 1px -1px rgba(239, 78, 236, 1)",
                                                    }}
                                                >
                                                    Spectator mode
                                                </Typography>
                                            )}

                                            {/* 2. end of question box*/}
                                            <Box
                                                ref={answersContainerRef}
                                                id="answerContainer"
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "100%",
                                                    position: "relative",
                                                    gap: "12px",
                                                    pl: {
                                                        xs: theme.pLeft
                                                            .secondary,
                                                        md: 0,
                                                    },
                                                    pr: {
                                                        xs: theme.pRight
                                                            .secondary,
                                                        md: 0,
                                                    },
                                                }}
                                                ref={answerContainerRef}
                                            >
                                                {questionObj.type !==
                                                    "input" && (
                                                    <MultipleAnswers
                                                        theme={theme}
                                                        answers={
                                                            questionObj.answers ??
                                                            TRUE_FALSE
                                                        }
                                                        buttonBackgroundColor={
                                                            theme.palette
                                                                .wmColor.main
                                                        }
                                                        questionStart={
                                                            questionStart
                                                        }
                                                        correctAnswer={
                                                            correctAnswer
                                                        }
                                                        clicked={clicked}
                                                        matches={matches}
                                                        selectedAns={
                                                            selectedAns
                                                        }
                                                        handleAnswerClick={
                                                            handleAnswerClick
                                                        }
                                                        spectator={me.spectator}
                                                        hideAnswer={hideAnswer}
                                                        yourAnswer={yourAnswer}
                                                    />
                                                )}
                                                {questionObj.type ===
                                                    "input" && (
                                                    <InputAnswer
                                                        style={{
                                                            marginTop: "20px",
                                                        }}
                                                        buttonBackgroundColor={
                                                            theme.palette
                                                                .wmColor.main
                                                        }
                                                        questionStart={
                                                            questionStart
                                                        }
                                                        correctAnswer={
                                                            correctAnswer
                                                        }
                                                        handleAnswerClick={
                                                            handleAnswerClick
                                                        }
                                                        spectator={me.spectator}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                                {me.spectator && (
                                    <Box sx={{ px: 3, mt: 5 }}>
                                        <ActiveGames
                                            uiLabels={uiLabels}
                                            header="Keep Playing"
                                            games={multiplayerCategory}
                                            theme={theme}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                    {[
                        GAME_STATUS.ROUND_ENDING,
                        GAME_STATUS.ROUND_WAITING,
                    ].includes(gameStatus) && (
                        <Box sx={{ px: { xs: "10px", md: "20%", lg: "25%" } }}>
                            <RoundBreak
                                gameStatus={gameStatus}
                                players={players}
                                me={me}
                                uiLabels={uiLabels}
                                theme={theme}
                                round={round}
                            />
                        </Box>
                    )}
                    {/* end of contentHolder box*/}

                    {message !== "" && (
                        <SnackBar open={openSnackbar} message={message} />
                    )}
                    <AlertDialog />
                </Container>
            </>
        ) : (
            <ErrorPage />
        ))
    );
};

export default Play;
