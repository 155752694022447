/* eslint-disable default-case */
import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//start import pages
import Home from "./pages/Home/Home";
import Lobby from "./pages/Lobby/Lobby";
import Play from "./pages/Play/Play";
import EndScreen from "./pages/EndScreen/EndScreen";

//end import pages

import useSocketEvents from "./hooks/useSocketEvents";
import {
    SOCKET_ACTIONS,
    SOCKET_RECEIVE_EVENTS,
    GAME_STATUS,
    UPDATE_UI_LABELS,
    coin,
    coinTitle,
    SOCKET_SEND_EVENTS,
} from "./helpers/constant";
import {
    populatePlayers,
    updateGameStatus,
    playerRemoved,
    playerJoined,
    updateRelatedVideos,
    updateWeeklyLeaderboard,
    updateSubcategory,
    updateGameDate,
    updateGameRequiredPoints,
    updateNextGameTime,
    updateTriviaLivestreams,
    updateAllTimeLeaderboard,
    updateGameThumbnail,
    updateSuperGameInfo,
    addPowerUps,
    serverStatus,
    updateQuestions,
    updateQuestionIndex,
    updateRound,
    updateLanguages,
    updateUILabels,
    updateMultiplayerCategory,
    updateMultiplayerVideo,
    updateRoundInfo,
} from "./reducers/GameReducer";

import { addMessage, addError } from "./reducers/MessageReducer";

import { updateQuestion } from "./reducers/QuestionsReducer";
import {
    saveYourProfile,
    updateIsSpectator,
    updatePoints,
    updateUsedPowerUps,
} from "./reducers/PlayerReducer";

import { useDispatch, useSelector } from "react-redux";

import {
    eraseGuestData,
    findPlayer,
    getGuestData,
    getLabel,
    putSelfToStart,
    saveGuestData,
    updateMojoPoints,
} from "./helpers/Global";

import { addTitle } from "./reducers/SettingsReducer";
import { addAlert, removeAlert } from "./reducers/AlertReducer";
import BackdropLoading from "./components/BackdropLoading/BackdropLoading";
import {
    addOnlinePlayer,
    removeOnlinePlayer,
    setOnlinePlayers,
} from "./reducers/OnlinePlayersReducer";

const GameRoutes = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const socketFromStore = useSelector(({ socket }) => socket.socket);

    const me = useSelector(({ player }) => player);

    const mePoints = useSelector(({ player }) => player.points);

    const gameStatus = useSelector(({ game }) => game.gameStatus);

    const players = useSelector(({ game }) => {
        return game.gamePlayers;
    });

    const languages = useSelector(({ game }) => {
        return game.languages;
    });

    const round = useSelector(({ game }) => {
        return game.round;
    });

    const uiLabels = useSelector(({ game }) => {
        return game.uiLabels;
    });

    const questions = useSelector(({ game }) => game.questions);

    const relatedVideos = useSelector(({ game }) => game.relatedVideos);
    const multiplayerCategory = useSelector(
        ({ game }) => game.multiplayerCategory
    );
    const multiplayerVideo = useSelector(({ game }) => game.multiplayerVideo);

    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");

    const handleMessage = useCallback(
        (event) => {
            const payload = event.data;

            if (payload.action) {
                switch (payload.action) {
                    //if host, once a roomId is created from reactApp update watchmojo url
                    case "backToHome":
                        navigate("/");
                        break;

                    //player accept challenge
                    case SOCKET_SEND_EVENTS.ACCEPT_CHALLENGE:
                        if (!socketFromStore) {
                            return;
                        }
                        socketFromStore.emit(
                            SOCKET_SEND_EVENTS.ACCEPT_CHALLENGE,
                            payload.payload
                        );

                        break;

                    //player accept challenge
                    case SOCKET_SEND_EVENTS.JOIN_ROOM:
                        navigate("/lobby");

                        break;
                }
            }
        },
        [socketFromStore, me]
    );

    useEffect(() => {
        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [socketFromStore]);

    useEffect(() => {
        updateMojoPoints(mePoints);
    }, [mePoints]);

    //monitor players list
    useEffect(() => {
        if (gameStatus === GAME_STATUS.COUNTDOWN) {
            populatePlayers(putSelfToStart(players, me.playerId, true));
        }
    }, [players]);

    useEffect(() => {
        //update parent url as well with roomId
        let message = {
            action: "updateLanguageList",
            languages: languages,
        };
        //method to send message to parent outside iframe
        window.top.postMessage(message, "*");
    }, [languages]);

    //useffect to redirect sections base on changes for gameStatus and me
    useEffect(() => {
        // console.log("GAME STATUS", gameStatus, me);
        const handleHideCookieScipt = () => {
            //update parent url as well with roomId
            let message = {
                action: "hideCookieScript",
            };
            //method to send message to parent outside iframe
            window.top.postMessage(message, "*");
        };

        switch (gameStatus) {
            case GAME_STATUS.WAITING:
                navigate("/");
                break;

            case GAME_STATUS.COUNTDOWN:
                handleHideCookieScipt();
                if (me.playerJoin === false && !me.playerQueue) {
                    navigate("/");
                    return;
                }

                if (!questions || questions.length === 0) {
                    return;
                }

                const find = players.find((p) => {
                    return p.id === me.playerId;
                });

                if (find) {
                    navigate("/lobby");
                }
                break;

            case GAME_STATUS.QUESTIONS:
                handleHideCookieScipt();
                if (me.playerJoin === false) {
                    navigate("/");
                    return;
                }

                if (!questions || questions.length === 0) {
                    return;
                }

                navigate("/play");
                break;

            case GAME_STATUS.END: {
                handleHideCookieScipt();

                const find = players.find((p) => {
                    return p.id === me.playerId;
                });

                //if player already redeemed then go back to main page
                if (me.playerJoin === true && me.playerRedeem === true) {
                    navigate("/gameover");
                    return;
                }

                if (me.playerJoin === false) {
                    // navigate("/gameover");
                    return;
                }

                //navigate to end page
                navigate("/end");
                break;
            }

            case GAME_STATUS.ROUND_WAITING:
                // console.log("ROUND_WAITING", me, round);
                if (me.playerJoin === false) {
                    navigate("/");
                    return;
                }

                if (round > 0) {
                    // navigate("/play");
                    return;
                }

                // navigate("/lobby");
                break;

            case GAME_STATUS.ROUND_ENDING:
                dispatch(updateQuestion(null, "", "", null, null, null));

                if (me.playerJoin === false) {
                    navigate("/");
                    return;
                }

                if (!questions || questions.length === 0) {
                    return;
                }

                // navigate("/play");
                break;
        }
    }, [gameStatus, me, questions, round, players]);

    useEffect(() => {
        if (!uiLabels || uiLabels.length === 0) {
            setLoadingMessage(
                `${getLabel(
                    uiLabels,
                    "labelLoadingGameInfo",
                    "Loading game info"
                )}...`
            );
            setIsLoading(true);
            return;
        }

        if (!me.playerId && (multiplayerCategory || multiplayerVideo)) {
            setIsLoading(false);
            return;
        }

        if (gameStatus === GAME_STATUS.END) {
            setIsLoading(false);
            return;
        }

        if (
            (questions.length === 0 && me.playerQueue !== false) ||
            (!multiplayerCategory && !multiplayerVideo)
        ) {
            setLoadingMessage(
                `${getLabel(
                    uiLabels,
                    "labelLoadingGameInfo",
                    "Loading game info"
                )}...`
            );
            setIsLoading(true);
            return;
        }

        setIsLoading(false);
    }, [
        questions,
        relatedVideos,
        gameStatus,
        me,
        uiLabels,
        multiplayerCategory,
        multiplayerVideo,
    ]);

    //add event to recceive server status
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.SERVER_STATUS,
        null,
        (status) => {
            dispatch(serverStatus(status));
        }
    );

    // receives game-details when new player joins
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.GAME_DETAILS,
        null,
        (action, payload) => {
            console.log(SOCKET_RECEIVE_EVENTS.GAME_DETAILS, action, payload);

            switch (action) {
                case "gameDetails":
                    //update players
                    dispatch(updateLanguages(payload.languages ?? []));
                    dispatch(
                        populatePlayers(
                            putSelfToStart(
                                payload.players ?? [],
                                me.playerId,
                                gameStatus === GAME_STATUS.COUNTDOWN
                                    ? true
                                    : false
                            )
                        )
                    );

                    dispatch(updateSubcategory(payload.subCategory));
                    dispatch(addTitle(payload.title ?? payload.subCategory));

                    dispatch(
                        updateGameRequiredPoints(
                            payload.requiredPoints ? payload.requiredPoints : 0
                        )
                    );

                    dispatch(updateGameDate(payload.date));

                    dispatch(
                        updateNextGameTime(
                            payload.nextGameTime ? payload.nextGameTime : null
                        )
                    );

                    dispatch(
                        updateGameThumbnail(payload.gameThumbnail ?? null)
                    );

                    dispatch(updateRound(payload.round ?? 0));
                    dispatch(updateRoundInfo(payload.roundInfo ?? null));
                    // dispatch(updateQuestions(payload.questions ?? []));
                    dispatch(updateQuestionIndex(payload.questionIndex ?? 0));

                    dispatch(addPowerUps(payload.powerUps ?? []));

                    //if no question then skip dispatch
                    if (!payload.question) {
                        break;
                    }

                    dispatch(
                        updateQuestion(
                            payload.question.id,
                            payload.question.question,
                            payload.question.videoTitle,
                            payload.question.type,
                            payload.question.answers,
                            payload.question.thumbnail
                        )
                    );

                    break;

                case "player":
                    dispatch(
                        saveYourProfile(
                            payload.playerId,
                            payload.playerName,
                            payload.playerAvatar,
                            payload.score,
                            payload.queue,
                            payload.join ?? false,
                            payload.redeem ?? false,
                            payload.inviteCode,
                            payload.hasConsent ?? false,
                            payload.spectator ?? false
                        )
                    );
                    break;

                case "relatedVideos":
                    dispatch(updateRelatedVideos(payload ?? []));
                    break;

                case "weeklyLeaderboard":
                    dispatch(updateWeeklyLeaderboard(payload ?? []));
                    break;

                case "allTimeLeaderboard":
                    dispatch(updateAllTimeLeaderboard(payload ?? []));
                    break;

                case "triviaLivestreams":
                    dispatch(updateTriviaLivestreams(payload ?? []));
                    break;

                case "superGame":
                    dispatch(updateSuperGameInfo(payload ?? null));
                    break;

                case "multiplayerRooms":
                    dispatch(
                        updateMultiplayerCategory(payload.endlessGames ?? [])
                    );

                    dispatch(updateMultiplayerVideo(payload.games ?? []));
                    break;
            }
        }
    );

    // receive update status
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.UPDATE_STATUS,
        null,
        (status, player) => {
            console.log(SOCKET_RECEIVE_EVENTS.UPDATE_STATUS, status, player);

            dispatch(updateGameStatus(status));
            const tempPlayer = getGuestData();

            if (!player && tempPlayer && !isNaN(tempPlayer.playerId)) {
                eraseGuestData();
                return;
            }

            if (!player) {
                return;
            }

            dispatch(
                saveYourProfile(
                    player.playerId,
                    player.playerName,
                    player.playerAvatar,
                    player.score,
                    player.queue,
                    player.join ?? false,
                    player.redeem ?? false,
                    player.inviteCode,
                    player.hasConsent ?? false,
                    player.spectator ?? false
                )
            );

            if (tempPlayer && tempPlayer.playerId == player.playerId) {
                saveGuestData(
                    JSON.stringify({
                        playerId: player.playerId,
                        playerName: tempPlayer.playerName,
                        playerAvatar: tempPlayer.playerAvatar,
                        ogPlayerAvatar:
                            tempPlayer && tempPlayer.ogPlayerAvatar
                                ? tempPlayer.ogPlayerAvatar
                                : player.playerAvatar,
                    })
                );
                return;
            }

            saveGuestData(
                JSON.stringify({
                    playerId: player.playerId,
                    playerName: player.playerName,
                    playerAvatar: player.playerAvatar,
                    ogPlayerAvatar: player.playerAvatar,
                })
            );
        }
    );

    //add event to require to login
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.LOGIN_REQUIRED,
        null,
        (message) => {
            dispatch(
                addAlert({
                    message: getLabel(
                        uiLabels,
                        "messagePleaseLoginToJoin",
                        message
                    ),
                    buttons: [
                        {
                            text: getLabel(uiLabels, "btnLogin", "Login"),
                            callback: () => {
                                let message = { action: "loginModal" };
                                // method to send message to parent outside iframe
                                window.parent.postMessage(message, "*");
                            },
                        },
                    ],
                })
            );
        }
    );

    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.RESET_GAME,
        null,
        () => {
            dispatch(
                saveYourProfile(
                    me.playerId,
                    me.playerName,
                    me.playerAvatar,
                    me.score,
                    null,
                    false,
                    false,
                    me.inviteCode,
                    me.hasConsent,
                    me.spectator
                )
            );
        }
    );

    // receive player removed message event
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYER_REMOVED,
        null,
        (playerId, playerName) => {
            dispatch(
                addMessage(
                    `<span style='color:#00b3e2'>${playerName}</span> has been removed`,
                    true
                )
            );
            // fix for player being disappearing after game end/disconnect event in the backend
            gameStatus !== GAME_STATUS.END && dispatch(playerRemoved(playerId));

            me.playerId == playerId &&
                dispatch(addError("You have been removed from the game"));
        }
    );

    // receive player joined event
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYER_JOINED,
        null,
        (player) => {
            console.log(SOCKET_RECEIVE_EVENTS.PLAYER_JOINED, player);
            dispatch(
                addMessage(
                    `<span style='color:#00b3e2'>${player.name}</span> joined the game`,
                    true
                )
            );
            dispatch(playerJoined(player));
        }
    );

    //callback when token authentication fails
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.CONNECT_ERROR,
        null,
        (err) => {
            //on connection refused, do something...
            console.log(err.message); // prints the message associated with the error
        }
    );

    //callback when Host changes game settings
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ROOM_FULL,
        null,
        (errorMessage) => {
            dispatch(addError(errorMessage));
        }
    );

    // when game ends
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.GAME_ENDS,
        null,
        useCallback(
            (players, roundWinners) => {
                // console.log("GAME_ENDS");
                dispatch(populatePlayers(players));

                //TODO : comment out for now since it's redundant, before receiving this event, we already set the game status to end
                //dispatch(updateGameStatus(GAME_STATUS.END));

                const player = players.find((p) => {
                    return p.id === me.playerId;
                });

                if (player && player.message && player.message !== "") {
                    // dispatch(addMessage(player.message, true));
                    let earnedPoints = 0;
                    const message = player.bonusPoints.map((point) => {
                        earnedPoints += point.points;
                        return point.message.replace(
                            /\+/g,
                            `&nbsp;&nbsp;${coin} `
                        );
                    });

                    dispatch(
                        addAlert({
                            title: `${coinTitle} MojoPoints Earned!`,
                            message: message.join("<br>"),
                            buttons: [
                                {
                                    text: "Ok",
                                    callback: () => {
                                        dispatch(removeAlert());
                                    },
                                },
                            ],
                        })
                    );

                    dispatch(updateMojoPoints(mePoints + earnedPoints));
                }
            },
            [me, mePoints]
        )
    );

    //callback when questions are updated
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.QUESTIONS_UPDATE,
        null,
        (questions) => {
            dispatch(updateQuestions(questions ?? []));
        }
    );

    //callback when players update
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYERS_UPDATE,
        null,
        useCallback(
            (players) => {
                console.log(SOCKET_RECEIVE_EVENTS.PLAYERS_UPDATE);
                dispatch(updateUsedPowerUps(findPlayer(players, me.playerId)));
                dispatch(populatePlayers(players));

                if (!me.spectator) {
                    const player = players.find((p) => {
                        return p.id === me.playerId;
                    });

                    if (!player) {
                        return;
                    }

                    dispatch(
                        updateIsSpectator(player.spectator ? true : false)
                    );
                }
            },
            [me]
        )
    );

    //callback to update round
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ROUND_UPDATE,
        null,
        (round) => {
            dispatch(updateRound(round));
        }
    );

    //callback to update round data info
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ROUND_INFO_UPDATE,
        null,
        (roundInfo) => {
            dispatch(updateRoundInfo(roundInfo));
        }
    );

    //callback to update question index
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.UPDATE_QUESTION_INDEX,
        null,
        (questionIndex) => {
            dispatch(updateQuestionIndex(questionIndex ?? 0));
        }
    );

    //callback to update power ups
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.POWERUPS,
        null,
        (powerUps) => {
            dispatch(addPowerUps(powerUps ?? []));
        }
    );

    //callback to update power points
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYER_POINTS,
        null,
        (points) => {
            dispatch(updatePoints(points ?? 0));
        }
    );

    const handleUILables = useCallback(
        (payload) => {
            console.log(SOCKET_RECEIVE_EVENTS.UILABELS, payload);
            const copyLabels = !uiLabels ? {} : { ...uiLabels };
            const keys = Object.keys(payload ?? {});

            if (keys.length === 0) {
                return;
            }

            keys.forEach((key) => {
                copyLabels[key] = payload[key];
            });

            dispatch(updateUILabels(copyLabels));

            let message = {
                action: UPDATE_UI_LABELS,
                uiLabels: copyLabels,
            };
            //method to send message to parent outside iframe
            window.top.postMessage(message, "*");
        },
        [dispatch, uiLabels]
    );

    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.UILABELS,
        null,
        handleUILables
    );

    // snackbar messages
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.SNACKBAR,
        null,
        (message, action, sticky) => {
            const snackbar = {
                message: `${message}`,
                action: action ?? null,
            };

            //method to send message to parent outside iframe
            window.top.postMessage(
                {
                    action: "snackbar",
                    snackbar: snackbar,
                    sticky: sticky ?? false,
                },
                "*"
            );
        }
    );

    // add event to refresh page
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.REFRESH,
        null,
        () => {
            //update parent url as well with roomId
            let message = { action: "refreshPage" };
            //method to send message to parent outside iframe
            window.top.postMessage(message, "*");
        }
    );

    // receive player disconnected from socket event
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.PLAYER_DISCONNECTED,
        null,
        (playerId, playerName) => {
            dispatch(
                addMessage(
                    `<span style='color:#00b3e2'>${playerName}</span> has disconnected`,
                    true
                )
            );
            gameStatus !== GAME_STATUS.END && dispatch(playerRemoved(playerId));
        }
    );

    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.HANDLE_ERROR,
        null,
        ({ title, message, action, alert = false, uiLabelKey }) => {
            if (!alert) {
                dispatch(addError(message));
                return;
            }

            const btnText = action ? "Ok" : "Ok";
            let btnCallback = () => {
                if (action && action === "refresh") {
                    //update parent url as well with roomId
                    let message = { action: "refreshPage" };
                    //method to send message to parent outside iframe
                    window.top.postMessage(message, "*");
                }
                dispatch(removeAlert());
            };

            if (uiLabelKey) {
                message = getLabel(uiLabels, uiLabelKey, message);
            }

            dispatch(
                addAlert({
                    title: title ?? "",
                    message: message ?? "",
                    buttons: [
                        {
                            text: btnText,
                            callback: btnCallback,
                        },
                    ],
                })
            );
        }
    );

    // event to receive list of online players
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ONLINE_PLAYERS,
        null,
        (onlinePlayers) => {
            console.log("ONLINE PLAYERS", onlinePlayers);
            dispatch(setOnlinePlayers(onlinePlayers));
        }
    );

    // event to add new online player
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.ADD_ONLINE_PLAYER,
        null,
        (onlinePlayer) => {
            dispatch(addOnlinePlayer(onlinePlayer));
        }
    );

    // event to remove online player
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.REMOVE_ONLINE_PLAYER,
        null,
        (playerId) => {
            dispatch(removeOnlinePlayer(playerId));
        }
    );

    // event to remove online player
    useSocketEvents(
        SOCKET_ACTIONS.RECEIVE,
        SOCKET_RECEIVE_EVENTS.CHALLENGE,
        null,
        (player) => {
            const snackbar = {
                message: `<span style='color:#00b3e2'>${player.playerName}</span> challenges you to play!`,
                action: {
                    label: "Accept",
                },
            };

            let message = {
                action: "snackbar",
                snackbar: snackbar,
                sticky: true,
                payload: player,
            };
            //method to send message to parent outside iframe
            window.top.postMessage(message, "*");
        }
    );

    return (
        <>
            <BackdropLoading open={isLoading} message={loadingMessage} />
            <Routes>
                <Route
                    exact
                    path="/"
                    element={
                        <Home
                            uiLabels={uiLabels}
                            socketFromStore={socketFromStore}
                        />
                    }
                />
                <Route
                    exact
                    path="/lobby"
                    element={
                        <Lobby
                            uiLabels={uiLabels}
                            socketFromStore={socketFromStore}
                        />
                    }
                />
                <Route
                    exact
                    path="/play"
                    element={
                        <Play
                            uiLabels={uiLabels}
                            socketFromStore={socketFromStore}
                        />
                    }
                />
                <Route
                    exact
                    path="/end"
                    element={
                        <EndScreen
                            uiLabels={uiLabels}
                            socketFromStore={socketFromStore}
                        />
                    }
                />
                <Route
                    exact
                    path="/gameover"
                    element={<EndScreen uiLabels={uiLabels} />}
                />
            </Routes>
        </>
    );
};

export default GameRoutes;
